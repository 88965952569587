import { ConsentMethodProvider, IConsentMethod } from '../../internal-types';

export class ConsentHelper {
  /**
   * Consent logic that derives whether the user has given consent to personalization.
   */
  public static initialiseConsent(
    countryCode: string,
    consentMethod: { [countryCode: string]: IConsentMethod } | undefined,
    userConsentOverride?: boolean
  ): boolean {
    if (userConsentOverride !== undefined) {
      return userConsentOverride;
    }

    let consentGranted: boolean | undefined;
    const countryConsent = consentMethod ? consentMethod[countryCode] : undefined;
    if (countryConsent) {
      switch (countryConsent.method) {
        case ConsentMethodProvider.ENSIGHTEN:
          consentGranted = ConsentHelper.getConsentFromEnsightenPrivacyApi(countryConsent?.cookie || '');
          break;
        case ConsentMethodProvider.ONETRUST:
          consentGranted = ConsentHelper.getConsentFromOneTrustApi(countryConsent?.group || '');
          break;
        case ConsentMethodProvider.ALWAYS_ON:
          consentGranted = true;
          break;
        case ConsentMethodProvider.ALWAYS_OFF:
          consentGranted = false;
          break;
      }
    }

    // The return value distinguishes between "consent given", "no consent"
    // and "unknown". For now "unknown" is interpreted as "no consent".
    if (consentGranted) {
      return consentGranted;
    }

    return false;
  }

  private static getConsentFromEnsightenPrivacyApi(cookie: string): boolean | undefined {
    try {
      const apiValue = window.Bootstrapper?.gateway?.getCookie(cookie);
      if (apiValue === '1') {
        return true;
      } else if (apiValue === '0') {
        return false;
      }
    } catch {
      /*
       * Catch all and ignore.
       * Possibly the Ensighten Library is not loaded yet.
       */
    }
    return undefined;
  }

  private static getConsentFromOneTrustApi(group: string): boolean | undefined {
    try {
      return window.OnetrustActiveGroups?.includes(group);
    } catch {
      /*
       * Catch all and ignore.
       * Possibly the OneTrust Library is not loaded yet.
       */
    }
    return undefined;
  }
}
