/**
 * Only put public interfaces in here
 */

/**
 * Info object that describes the personalization decision of the service
 *
 * @deprecated: use personalization service version 2 and IContentVariant
 */
export interface IPersonalizationInfo {
  /**
   * Name of the content variant that should be played out by the feature app
   */
  variant: string;

  /**
   * Version of the configuration of the use case in question.
   * Usually used to describe the different iteration stages when optimizing a use case for performance.
   */
  iterationId?: string;

  /**
   * Parameter that should be passed to the tracking system in addition to the variant and the version.
   * Usually used to distinguish between tets and control groups.
   */
  trackParam?: string;
}

/**
 * Info object that describes the personalization decision of the service
 */
export interface IContentVariant {
  /**
   * Name of the content variant that should be played out by the feature app
   */
  contentId: string;

  /**
   * Version of the configuration of the use case in question.
   * Usually used to describe the different iteration stages when optimizing a use case for performance.
   */
  iterationId?: string;

  /**
   * Parameter that should be passed to the tracking system in addition to the variant and the version.
   * Usually used to distinguish between tets and control groups.
   */
  trackParam?: string;
}

/**
 * @deprecated used for version 1. Use PersonalizationDataSource for version 2
 */
export enum PersonalizationType {
  // Personalization based on user-behaviour/-profile
  Behavioural,

  // Personalization based on csref-campaign-parameter
  Campaign,
}

export enum PersonalizationDataSource {
  // Personalization based on user-behaviour/-profile
  Behavioural,

  // Personalization based on csref-campaign-parameter
  Campaign,
}

/**
 * Personalization service to be used in Audi contexts.
 * @deprecated: use {@link IPersonalizationServiceV2} instead.
 */
export interface IPersonalizationServiceV1 {
  /**
   * @deprecated: Use {@link getVariantInfo} instead.
   *
   * Provides a decision on a named personalization use case with respect to the current user.
   *
   * The usage of the service is based on conventions, i.e. a feature app and the service rely on consistent naming of use cases and variants.
   *
   * The services solves (hides) the identification and consent handling for (from) all consumers, i.e.
   * (1) it identifies the user via cookies (mainly analytics) and/or login ids.
   * (2) it takes care of multi-device usage via a device tree (possibly on the server)
   * (3) it checks the consent of the user for the specific use case. If no consent is given the 'default' is returned.
   *
   * The services solves (hides) the personalization logic for (from) all consumers, i.e.
   * (1) it resolves the user's profile from it's backend (asynchronously)
   * (2) it manages the user's profile on client side and cares for necessary updates from the server
   * (3) it enriches the user's profile with additional client-side information, i.e. browser type, campaign parameters, ...
   * (4) it applies the decision logic for a certain use case on the user profile to derive the user's variant (next best action)
   *
   * @param name the name of the use case, e.g. 'country_home_highlighted_car'.
   * @param additionalSegments list of additional info provided to the personalization decision, list entries are handled as segments
   *
   * @returns the info object that describes the variant that should be displayed.
   */
  getUseCaseVariantInfo(name: string, additionalSegments?: string[]): IPersonalizationInfo;

  /**
   * @deprecated Use {@link getVariantInfo} instead.
   *
   * Convenience method - returns only the variant name as a string, i.e. omits the additional members of the info object.
   */
  getUseCaseVariant(name: string, additionalSegments?: string[]): string | undefined;

  /**
   * @deprecated Returns obfuscated segments and thus is useless.
   *
   * Provides the current user's segments.
   *
   * @returns the list of segments the user belongs to, e.g. ['affinity_a3','country_home_highlighted_car_a3_sportback'].
   */
  getUserSegments(): string[];

  /**
   * Single entry-point to retrieve personalization-information based on the given personalization-type.
   *
   * @param name The use-case-name to identify the current personalization-context
   * @param featureAppId A unique feature-app-id to differ between different feature-apps of same type on one page
   * @param personalizationType Indicates the personalization-type/-logic/-algorithm
   */
  getVariantInfo(
    name: string,
    featureAppId: string,
    personalizationType: PersonalizationType
  ): Promise<IPersonalizationInfo>;
}

/**
 * Personalization service to be used in Audi contexts. V2 removes deprecated functions
 */
export interface IPersonalizationServiceV2 {
  /**
   * Single entry-point to retrieve personalization-information based on the given personalization-type.
   *
   * @param useCaseName The use-case-name to identify the current personalization-context
   * @param featureAppId A unique feature-app-id to differ between different feature-apps of same type on one page
   * @param personalizationDataSource Indicates the personalization-type/-logic/-algorithm
   */
  getContentVariant(
    useCaseName: string,
    featureAppId: string,
    personalizationDataSource: PersonalizationDataSource
  ): Promise<IContentVariant>;

  /**
   * Calculates an array of personalizationInfo objects, sorted with the preferences of the user
   *
   * @param contentIds a list of content ids
   * @param featureAppId A unique feature-app-id to differ between different feature-apps of same type on one page
   * @param useCaseName The use-case-name (used for different pages or use-case configurations) to identify the current personalization-context. Optional
   * @param personalizationDataSource Indicates the personalization-type/-logic/-algorithm. Optional
   * @returns a promise for an array with all IPersonalizationInfo objects created with the variant ids. Sorted with the users preferences
   */
  getSortedContentVariants(
    contentIds: string[],
    featureAppId: string,
    useCaseName?: string,
    personalizationDataSource?: PersonalizationDataSource
  ): Promise<IContentVariant[]>;
}

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    // Ensighten
    Bootstrapper?: {
      gateway: {
        getCookie(cookie: string): string;
      };
    };

    /**
     * OneTrust typing stub.
     * See https://community.cookiepro.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226
     */
    OnetrustActiveGroups?: string;
  }
}
