import { Logger } from '@feature-hub/logger';
import { ServerRequestV1 } from '@feature-hub/server-request';

import { PersonalizationBaseService } from '../shared/PersonalizationBaseService';
import { PersonalizationConfigService } from '../shared/PersonalizationConfigService';
import { PersonalizationInfoHelper } from './../shared/helper/personalization-info.helper';
import { IPersonalizationServiceV2, PersonalizationDataSource, IContentVariant } from '../types';

export class PersonalizationServiceV2 extends PersonalizationBaseService implements IPersonalizationServiceV2 {
  public constructor(
    readonly personalizationConfigService: PersonalizationConfigService,
    readonly logger?: Logger,
    readonly serverRequest?: ServerRequestV1
  ) {
    super(personalizationConfigService, logger, serverRequest);
  }

  public async getContentVariant(
    useCaseName: string,
    featureAppId: string,
    personalizationType: PersonalizationDataSource = PersonalizationDataSource.Behavioural
  ): Promise<IContentVariant> {
    switch (personalizationType) {
      case PersonalizationDataSource.Behavioural:
        return Promise.resolve<IContentVariant>(
          PersonalizationInfoHelper.mapToContentVariant(this.getUseCaseVariantInfo(useCaseName))
        );
      case PersonalizationDataSource.Campaign:
      default:
        return this.getCsrefVariantInfo(featureAppId) as Promise<IContentVariant>;
    }
  }

  public async getSortedContentVariants(
    contentIds: string[],
    featureAppId: string,
    useCaseName?: string,
    personalizationType: PersonalizationDataSource = PersonalizationDataSource.Behavioural
  ): Promise<IContentVariant[]> {
    let sortedList: IContentVariant[] = [];
    if (contentIds?.length) {
      sortedList = contentIds.map((id) =>
        PersonalizationInfoHelper.mapToContentVariant(PersonalizationInfoHelper.compilePersonalizationInfo(id))
      );
    }
    return Promise.resolve<IContentVariant[]>(sortedList.reverse());
  }

  /**
   * returns a variant determined for a configured campaign
   * @param featureAppId
   * @returns a content variant in case a csref parameter can be found and a matching campaign is defined in the backend. Otherwise a default variant is returned
   */
  private async getCsrefVariantInfo(featureAppId: string): Promise<IContentVariant> {
    let info: IContentVariant = {
      contentId: PersonalizationConfigService.DEFAULT_VARIANT,
      trackParam: PersonalizationConfigService.DEFAULT_TRACK_PARAM,
    };
    const csref = this.readCsrefParameter();
    if (!csref || !csref.campaignName() || !csref.additionalInformation()) {
      return info;
    }

    const campaigns = await this.personalizationConfigService.getCsrefCampaignsFromBackend();
    const CAMPAIGN_KEY = 'campaigns';
    const ai = campaigns?.[CAMPAIGN_KEY]?.[csref.campaignName()]?.[csref.additionalInformation()];
    if (ai) {
      if (ai['*']) {
        info = this.mapVariantToContentVariant(ai['*']);
      } else if (ai[featureAppId]) {
        info = this.mapVariantToContentVariant(ai[featureAppId]);
      }
    }

    return info;
  }

  private mapVariantToContentVariant(data: any) {
    const contentVariant = { ...data };
    contentVariant.contentId = contentVariant.variant;
    delete contentVariant.variant;
    return contentVariant as IContentVariant;
  }
}
