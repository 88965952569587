/**
 * Interface used to model nested variants in {@link IPersonalizationOverrides.useCaseOverrides}.
 */
export interface IVariant {
  variant: string;
  trackParam?: string;
  variants?: {
    [segment: string]: IVariant;
  };
}

/**
 * Override information to change the service behaviour e.g. for automated and manual tests.
 */
export interface IPersonalizationOverrides {
  /**
   * Use case mapping: Sets a Mapping from user segments to variants for every
   * use case allowing to nest variants.
   */
  useCaseOverrides?: {
    [uc: string]: {
      iterationId?: string;
      variant?: string;
      trackParam?: string;
      variants: {
        [segment: string]: IVariant;
      };
    };
  };

  /**
   * Sets the segments the current user belongs to by their names.
   */
  segmentOverrides?: string[];

  /**
   * Sets the users consent (for tests and demos).
   */
  userConsentOverride?: boolean;
}

/**
 * Interface for the consent method structure.
 */
export interface IConsentMethod {
  method: ConsentMethodProvider;
  cookie?: string;
  group?: string;
}

export enum ConsentMethodProvider {
  ENSIGHTEN = 'ensighten',
  ONETRUST = 'onetrust',
  ALWAYS_ON = 'alwaysOn',
  ALWAYS_OFF = 'alwaysOff',
}

/**
 * Structure & types of the configuration expected from the Env Config Service.
 */
export interface IPersonalizationConfig {
  /**
   * Endpoint to get a map of all use cases
   */
  useCasesUrl?: string;

  /**
   * Endpoint to retrieve campaign-configurations based on csref-parameters.
   */
  csrefCampaignsUrl?: string;

  /**
   * Endpoint to get a single user's segments.
   * Not that this is a url-template which has to be expanded with a user id.
   */
  segmentsUrl?: string;

  /**
   * Correspondence between countryCode/markets and cookie consent methods.
   * It is an object whose keys are countryCodes and values are methods' names.
   */
  consentMethod: { [countryCode: string]: IConsentMethod };
}
