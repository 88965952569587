import { PersonalizationConfigService } from './../shared/PersonalizationConfigService';
import { PersonalizationBaseService } from './../shared/PersonalizationBaseService';
import { Logger } from '@feature-hub/logger';

import { IPersonalizationServiceV1, IPersonalizationInfo, PersonalizationType } from '../types';
import { ServerRequestV1 } from '@feature-hub/server-request';

export class PersonalizationServiceV1 extends PersonalizationBaseService implements IPersonalizationServiceV1 {
  public constructor(
    readonly personalizationConfigService: PersonalizationConfigService,
    readonly logger?: Logger,
    readonly serverRequest?: ServerRequestV1
  ) {
    super(personalizationConfigService, logger, serverRequest);
  }

  public getUseCaseVariant(name: string, additionalSegments?: string[]): string | undefined {
    /*
     * Dirty Hack ahead:
     *
     * For the Go Live of the eTron GT we need to rely on an existing implementation of the Highlighted Car Teaser that
     * cannot be changed in time. We therefor TEMPORARILY change the semantics of the method (which is used by the
     * aforementioned teaser) as follows:
     * - if any decision is taken via the segments of the user we return the variant name
     * - the includes the control groups where we simply return 'default' as the variant name
     * - in all 'fallback cases' that usually also return 'default' we now return 'undefined'
     * With this semantic the Highlighted Car Teaser can distinguish between test groups, control groups and other cases
     * while only receiving one return value, i.e. the variant name.
     *
     * To provide this behaviour that intentionally differs from the behaviour of getUseCaseVariantInfo
     * we even duplicated code with the following roadmap in mind:
     * - With the Go Live of the eTron GT the Highlighted Car Teaser will use this 'hacky method'
     *   but the 'correct method' is already available≥
     * - With one of the next releases the Highlighted Car Teaser will have implemented to use the 'correct method'
     * - As soon as all systems use the Highlighted Car Teaser we will remove this method and enforce using the trackParam.
     */
    if (this.personalizationConfigService.consentGiven) {
      try {
        const useCaseForName = this.personalizationConfigService.useCases[name] as any;
        if (useCaseForName) {
          const allSegments = [...(additionalSegments || []), ...this.personalizationConfigService.segments];
          const variantInfo = this.recurseOnVariants(allSegments, useCaseForName as any, useCaseForName.iterationId);
          if (variantInfo) {
            return variantInfo.variant;
          }
        }
      } catch (e) {
        /*
         * Catch all and ignore.
         * If something goes wrong the caller can always live with the default.
         */
        this.logger?.warn('use case retrieval failed');
      }
    }
    return undefined;
  }

  public async getVariantInfo(
    name: string,
    featureAppId: string,
    personalizationType: PersonalizationType = PersonalizationType.Behavioural
  ): Promise<IPersonalizationInfo> {
    switch (personalizationType) {
      case PersonalizationType.Behavioural:
        return this.getUseCaseVariantInfo(name);
      case PersonalizationType.Campaign:
      default:
        return this.getCsrefVariantInfo(name, featureAppId);
    }
  }

  public getUserSegments(): string[] {
    if (this.personalizationConfigService.consentGiven) {
      return this.personalizationConfigService.segments;
    }
    return [];
  }

  /**
   * returns a variant determined for a configured campaign
   * @param name no use at the moment
   * @param featureAppId
   * @returns
   */
  public async getCsrefVariantInfo(name: string, featureAppId: string): Promise<IPersonalizationInfo> {
    let info: IPersonalizationInfo = {
      variant: PersonalizationConfigService.DEFAULT_VARIANT,
      trackParam: PersonalizationConfigService.DEFAULT_TRACK_PARAM,
    };
    const csref = this.readCsrefParameter();
    if (csref && csref.campaignName() && csref.additionalInformation()) {
      const campaigns = await this.personalizationConfigService.getCsrefCampaignsFromBackend();
      const CAMPAIGN_KEY = 'campaigns';
      if (
        campaigns &&
        campaigns[CAMPAIGN_KEY] &&
        campaigns[CAMPAIGN_KEY][csref.campaignName()] &&
        campaigns[CAMPAIGN_KEY][csref.campaignName()][csref.additionalInformation()]
      ) {
        const ai = campaigns[CAMPAIGN_KEY][csref.campaignName()][csref.additionalInformation()];
        if (ai['*']) {
          info = ai['*'] as IPersonalizationInfo;
        } else if (ai[featureAppId]) {
          info = ai[featureAppId] as IPersonalizationInfo;
        }
      }
    }

    return info;
  }
}
