import { Logger } from '@feature-hub/logger';
import { IPersonalizationConfig, IPersonalizationOverrides } from '../internal-types';
import { EnvConfigServiceV1 } from '@volkswagen-onehub/audi-env-config-service/dist/index';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service/dist-es';
import axios from 'axios';
import {
  ConsentHelper,
  SegmentHelper,
  SEGMENT_LOCAL_STORAGE_KEY,
  UseCaseHelper,
  UserIdHelper,
  USE_CASE_LOCAL_STORAGE_KEY,
} from './helper';

export class PersonalizationConfigService {
  public static ENV_CONFIG_NAME = 'personalization';
  public static DEFAULT_VARIANT = 'default';
  public static DEFAULT_TRACK_PARAM = 'no-match#none';
  private countryCode: string = '';
  private userId?: string;
  // tslint:disable-next-line: variable-name
  private _consentGiven: boolean = false;
  // tslint:disable-next-line: variable-name
  private _useCases: { [key: string]: object } = {};
  // tslint:disable-next-line: variable-name
  private _segments: string[] = [];

  public get consentGiven() {
    return this._consentGiven;
  }

  public get useCases() {
    return this._useCases;
  }

  public get segments() {
    return this._segments;
  }

  public constructor(
    protected readonly localeService: LocaleServiceV1,
    protected readonly envConfigService: EnvConfigServiceV1,
    protected readonly overrides?: IPersonalizationOverrides,
    protected readonly logger?: Logger
  ) {
    this.countryCode = this.localeService.countryCode.toUpperCase();
    this.getConfig()
      .then((config: IPersonalizationConfig | undefined) => {
        this._consentGiven = ConsentHelper.initialiseConsent(
          this.countryCode,
          config?.consentMethod,
          overrides?.userConsentOverride
        );
        if (this._consentGiven) {
          this.initialize();
        }
      })
      .catch((_) => {
        /* nothing todo */
      });

    // This code runs before we obtain the envConfigService and it's
    // initialized with the values coming in the overrides.
    if (overrides?.userConsentOverride) {
      this._consentGiven = true;
      this.initialize();
    }
  }

  public async getCsrefCampaignsFromBackend(): Promise<{ [key: string]: object }> {
    return this.getFromBackend((config: IPersonalizationConfig | undefined) => config?.csrefCampaignsUrl) as Promise<{
      [key: string]: object;
    }>;
  }

  protected initialize() {
    this.userId = UserIdHelper.initialiseUserId();
    this._useCases = UseCaseHelper.initialiseUseCases(this.overrides?.useCaseOverrides);
    this._segments = SegmentHelper.initialiseSegments(this.overrides?.segmentOverrides);

    void this.updateUseCases();
    void this.updateSegments();
  }

  protected async getConfig(): Promise<IPersonalizationConfig | undefined> {
    const rawConfig = await this.envConfigService.getConfiguration(PersonalizationConfigService.ENV_CONFIG_NAME);
    if (rawConfig) {
      return rawConfig as IPersonalizationConfig;
    }
    return undefined;
  }

  /**
   * Asynchronous update of the use cases definitions in, both, member variable and local storage.
   */
  private async updateUseCases(): Promise<void> {
    this._useCases = await this.retrieveUseCases();
    try {
      localStorage.setItem(USE_CASE_LOCAL_STORAGE_KEY, JSON.stringify(this._useCases));
    } catch (e) {
      //
    }
  }

  /**
   * Asynchronous retrieval of the use cases definitions.
   * If no overrides are present the method calls the backend.
   */
  private async retrieveUseCases(): Promise<{ [key: string]: object }> {
    if (this.overrides?.useCaseOverrides) {
      return this.overrides?.useCaseOverrides;
    }
    const fromBackend = await this.getUseCasesFromBackend();
    if (fromBackend) {
      return fromBackend;
    }
    return {};
  }

  private async getUseCasesFromBackend(): Promise<{ [key: string]: object }> {
    return this.getFromBackend((config: IPersonalizationConfig | undefined) => config?.useCasesUrl);
  }

  private async getFromBackend(
    urlFromConfig: (config: IPersonalizationConfig | undefined) => string | undefined
  ): Promise<{ [key: string]: object }> {
    try {
      let targetUrl = urlFromConfig(await this.getConfig());
      if (targetUrl) {
        targetUrl = targetUrl.replace('{market}', this.countryCode);
        const response = await axios.get(targetUrl);
        if (response.status === 200) {
          return response.data;
        }
      }
    } catch (e) {
      this.logger?.error('Unable to read data from backend', e);
    }
    return {};
  }

  /**
   * Asynchronous update of the user segments in, both, member variable and local storage.
   */
  private async updateSegments(): Promise<void> {
    this._segments = await this.retrieveSegments();
    try {
      localStorage.setItem(SEGMENT_LOCAL_STORAGE_KEY, JSON.stringify(this.segments));
    } catch (e) {
      //
    }
  }

  /**
   * Asynchronous retrieval of the user segments.
   * If no overrides are present the method calls the backend.
   */
  private async retrieveSegments(): Promise<string[]> {
    const fromUrl = SegmentHelper.getSegmentsFromUrl();
    if (fromUrl) {
      return fromUrl;
    }
    if (this.overrides?.segmentOverrides) {
      return this.overrides?.segmentOverrides;
    }
    const fromBackend = this.getSegmentsFromBackend();
    if (fromBackend) {
      return fromBackend;
    }
    return [];
  }

  /**
   * Backend call for the user segments.
   */
  private async getSegmentsFromBackend(): Promise<string[]> {
    try {
      if (!this.userId) {
        return [];
      }

      let segmentsUrl = (await this.getConfig())?.segmentsUrl;
      if (segmentsUrl) {
        segmentsUrl = segmentsUrl.replace('{market}', this.countryCode).replace('{uid}', this.userId);
        if (segmentsUrl) {
          const response = await axios.get(segmentsUrl);
          if (response.status === 200) {
            const userProfile = (await response.data) as any;
            if (userProfile?.segments) {
              return userProfile.segments;
            }
          }
        }
      }
    } catch (e) {
      //
    }
    return [];
  }
}
