const OVERRIDE_SEGMENT_PARAM = 'personalization_override';
export const SEGMENT_LOCAL_STORAGE_KEY = 'audiPersonalizationSegments';

export class SegmentHelper {
  /**
   * Helper function to initialise the user segments.
   * This method is called from the constructor, should return immediately, and does not contain a backend request.
   */
  public static initialiseSegments(segmentOverrides?: string[]): string[] {
    const fromUrl = SegmentHelper.getSegmentsFromUrl();
    if (fromUrl) {
      return fromUrl;
    }
    if (segmentOverrides) {
      return segmentOverrides;
    }
    const fromLocalStorage = SegmentHelper.getSegmentsFromLocalStorage();
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
    return [];
  }

  /**
   * Helper function to extract segments from a query parameter in the current page url.
   */
  public static getSegmentsFromUrl(): string[] | undefined {
    try {
      const paramString = window?.location?.search?.substring(1);
      if (paramString) {
        const params = new URLSearchParams(paramString);
        const segmentString = params.get(OVERRIDE_SEGMENT_PARAM);
        if (segmentString) {
          const segments = segmentString.split(',');
          if (segments && segments.length > 0) {
            return segments;
          }
        }
      }
    } catch (e) {
      /*
       * Catch all and ignore.
       * Overrides are intended to be used for manual testing. We rather lose overrides than the service.
       */
      // console.log("override segments cannot be parsed from url param: " + paramString)
    }
    return undefined;
  }

  /**
   * Helper function to get the user segments from local storage.
   */
  private static getSegmentsFromLocalStorage(): string[] | undefined {
    try {
      const fromLocalStorage = localStorage.getItem(SEGMENT_LOCAL_STORAGE_KEY);
      if (fromLocalStorage) {
        const parsed = JSON.parse(fromLocalStorage);
        if (parsed && Array.isArray(parsed)) {
          return parsed;
        }
      }
    } catch (e) {
      /*
       * Catch all and ignore.
       * If local storage is not supported or if the content is not formatted properly we just ignore it.
       */
    }
    return undefined;
  }
}
