const OVERRIDE_USER_ID_PARAM = 'personalization_userid';
const USER_ID_MIN_LENGTH = 2;
const USER_ID_COOKIE_NAME = 'AMCV_097B467352782F130A490D45%40AdobeOrg';
const USER_ID_COOKIE_KEY = 'MCMID';

export class UserIdHelper {
  /**
   * UserId logic implementing the precedence of the different sources the user id can be extracted from.
   *
   * Overrides come first.
   * After that the 'well defined API' of the Adobe Visitor Id Service takes precedence. If the service is not present or
   * not initialised yet we fallback onto our own extraction of the corresponding Adobe cookie.
   */
  public static initialiseUserId(): string | undefined {
    const fromOveride = UserIdHelper.getUserIdFromOverrideParam();
    if (fromOveride) {
      return fromOveride;
    }
    const fromApi = UserIdHelper.getUserIdFromAdobeAPI();
    if (fromApi && fromApi.length > USER_ID_MIN_LENGTH) {
      return fromApi;
    }
    const fromCookie = UserIdHelper.getUserIdFromAdobeCookie();
    if (fromCookie && fromCookie.length > USER_ID_MIN_LENGTH) {
      return fromCookie;
    }
    return undefined;
  }

  /**
   * Helper function to extract a cookie with a given name.
   */
  private static getCookieValue(cookieName: string): string {
    const cookieMatch = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    const cookieValue = cookieMatch?.pop();
    return cookieValue ? cookieValue : '';
  }

  /**
   * Helper function to parse the Adobe Cookie and extract the Marketing Cloud Visitor Id.
   * The AMCV cookie consists of pipe separated key value pairs with the first key at second position,
   * cf. https://docs.adobe.com/content/help/en/id-service/using/intro/cookies.html
   * We therefor iterate over the keys and extract the following value as soon as we have reached the correct entry.
   */
  private static getUserIdFromAdobeCookie(): string | undefined {
    try {
      const cookieValue = decodeURI(this.getCookieValue(USER_ID_COOKIE_NAME));
      const cookieParts = cookieValue?.split('|');
      if (cookieParts && Array.isArray(cookieParts) && cookieParts.length >= 3) {
        for (let i = 1; i < cookieParts.length; i += 2) {
          const cookieKey = cookieParts[i];
          if (cookieKey && cookieKey === USER_ID_COOKIE_KEY && cookieParts.length > i + 1) {
            return cookieParts[i + 1];
          }
        }
      }
    } catch (e) {
      /*
       * Catch all and ignore.
       * We know that a "foreign" Cookie is not a stable API for accessing the Adobe Marketing Cloud Visitor Id.
       */
    }
    return undefined;
  }

  /**
   * Helper function to extract the Marketing Cloud Visitor Id from the visitor API of the Adobe Visitor Id Service.
   * cf. https://docs.adobe.com/content/help/en/id-service/using/id-service-api/methods/getmcvid.html
   */
  private static getUserIdFromAdobeAPI(): string | undefined {
    try {
      return (window as any).visitor.getMarketingCloudVisitorID();
    } catch (e) {
      /*
       * Catch all and ignore.
       * We can't do anything here if the Adobe Marketing Cloud Visitor Id Service is not initialised yet.
       */
    }
    return undefined;
  }

  /**
   * Helper function to extract an override User Id for testing purposes from a parameter of the current URL query string.
   */
  private static getUserIdFromOverrideParam(): string | undefined {
    try {
      const paramString = window?.location?.search?.substring(1);
      if (paramString) {
        const params = new URLSearchParams(paramString);
        const userId = params.get(OVERRIDE_USER_ID_PARAM);
        if (userId) {
          return userId;
        }
      }
    } catch (e) {
      /*
       * Catch all and ignore.
       * Overrides are intended to be used for manual testing. We rather lose overrides than the service.
       */
    }
    return undefined;
  }
}
