import { Logger } from '@feature-hub/logger';

import { IPersonalizationInfo } from '../types';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { Csref } from '../Csref';
import { PersonalizationInfoHelper } from './helper';
import { PersonalizationConfigService } from './PersonalizationConfigService';

export class PersonalizationBaseService {
  public constructor(
    protected readonly personalizationConfigService: PersonalizationConfigService,
    protected readonly logger?: Logger,
    protected readonly serverRequest?: ServerRequestV1
  ) {}

  public readCsrefParameter(): Csref | undefined {
    let csref;

    if (this.serverRequest) {
      try {
        csref = Csref.fromUrl(this.serverRequest.url);
      } catch (e) {
        this.logger?.error(`Invalid URL from server-request-service '${this.serverRequest.url}'`, e);
      }
    } else if (typeof window !== undefined) {
      try {
        csref = Csref.fromUrl(window?.location?.search);
      } catch (e) {
        this.logger?.error(`Invalid URL from window-location '${window?.location?.search}'`, e);
      }
    }
    return csref;
  }

  /**
   *
   * Provides a decision on a named personalization use case with respect to the current user.
   *
   * The usage of the service is based on conventions, i.e. a feature app and the service rely on consistent naming of use cases and variants.
   *
   * The services solves (hides) the identification and consent handling for (from) all consumers, i.e.
   * (1) it identifies the user via cookies (mainly analytics) and/or login ids.
   * (2) it takes care of multi-device usage via a device tree (possibly on the server)
   * (3) it checks the consent of the user for the specific use case. If no consent is given the 'default' is returned.
   *
   * The services solves (hides) the personalization logic for (from) all consumers, i.e.
   * (1) it resolves the user's profile from it's backend (asynchronously)
   * (2) it manages the user's profile on client side and cares for necessary updates from the server
   * (3) it enriches the user's profile with additional client-side information, i.e. browser type, campaign parameters, ...
   * (4) it applies the decision logic for a certain use case on the user profile to derive the user's variant (next best action)
   *
   * @param name the name of the use case, e.g. 'country_home_highlighted_car'.
   * @param additionalSegments list of additional info provided to the personalization decision, list entries are handled as segments
   *
   * @returns the info object that describes the variant that should be displayed.
   */
  public getUseCaseVariantInfo(name: string, additionalSegments?: string[]): IPersonalizationInfo {
    if (this.personalizationConfigService.consentGiven) {
      try {
        const useCaseForName = this.personalizationConfigService.useCases[name] as any;
        if (useCaseForName) {
          const allSegments = [...(additionalSegments || []), ...this.personalizationConfigService.segments];
          const variantInfo = this.recurseOnVariants(allSegments, useCaseForName as any, useCaseForName.iterationId);
          if (variantInfo) {
            return variantInfo;
          } else if (useCaseForName.variant) {
            return PersonalizationInfoHelper.compilePersonalizationInfo(
              useCaseForName.variant,
              useCaseForName.trackParam,
              useCaseForName.iterationId
            );
          }
        }
      } catch (e) {
        /*
         * Catch all and ignore.
         * If something goes wrong the caller can always live with the default.
         */
        this.logger?.warn('use case retrieval failed');
      }
    }
    return PersonalizationInfoHelper.compilePersonalizationInfo(PersonalizationConfigService.DEFAULT_VARIANT);
  }

  /**
   * Recursive method to determine the variant.
   */
  protected recurseOnVariants(segments: string[], refinement: any, iterationId?: string): IPersonalizationInfo | null {
    const variants = refinement.variants || {};
    /*
     * The user's segments are filtered for performance reasons: We potentially have many segments and
     * we do not want to iterate over segments that do not match our use case.
     * Note: Segments are provided in order, please keep the order.
     */
    const relevantSegments = segments.filter((segment) => Object.keys(variants).includes(segment));
    for (const segment of relevantSegments) {
      /*
       * Variants have a tree structure that is matched recursively:
       * If a user's segment matches a branch we try to descend and match the substructure.
       * As soon as no further branch matches (or the maximum depth of the tree is reached) we
       * return the 'deepest' variant we have found.
       * Again: The user's segments are ordered by their precedence. This means that we do not
       * have to consider any neighbouring branches as soon as the first segment matches.
       */
      const variant = variants[segment];
      if (variant) {
        /*
         * We have a match, i.e. we first recursively check for 'deeper' matches.
         * If the recursion provides a result we have a 'deeper' match. Otherwise we return
         * the current info.
         */
        const refinedVariant = this.recurseOnVariants(segments, variant, iterationId);
        if (refinedVariant) {
          return refinedVariant;
        } else if (variant?.variant && typeof variant.variant === 'string') {
          return PersonalizationInfoHelper.compilePersonalizationInfo(variant.variant, variant.trackParam, iterationId);
        }
      }
    }
    return null;
  }
}
