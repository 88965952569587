import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button, ButtonGroup, Text } from '@audi/audi-ui-react';
import { HighlightedCarTeaserProps, WltpProps } from './ComponentTypes';
import {
  BlockSpan,
  StyledPictureContainer,
  StyledPicture,
  StyledImg,
  StyledHighlightedCarTeaser,
  StyledLayoutItemText,
} from './styles';

// TODO: this is a hack, correct CSS resets should be provided by PlatformProvider
const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    box-sizing: border-box;
  }
`;
GlobalStyles.displayName = 'GlobalStyles';

export const HighlightedCarTeaser: React.FunctionComponent<HighlightedCarTeaserProps> = ({
  linkTracking,
  headline,
  wltpData,
  additionalLegalText,
  image,
  primaryLink,
  secondaryLink,
  spacingOption,
}: HighlightedCarTeaserProps) => {
  const renderWltpData = (
    { formattedConsumption, formattedEmission }: WltpProps,
    index: number
  ) => (
    <Text as="p" variant="copy2" spaceStackEnd="s" key={index}>
      <BlockSpan>{formattedConsumption}</BlockSpan>
      <BlockSpan>{formattedEmission}</BlockSpan>
    </Text>
  );
  return (
    <>
      <GlobalStyles />
      <StyledHighlightedCarTeaser
        data-testid="highlightedCarTeaserId"
        spacingOption={spacingOption?.spacing || 'large'}
        direction={{ xs: 'column', m: 'row' }}
      >
        <StyledPictureContainer basis="50%" shrink="0">
          <StyledPicture>
            <source srcSet={image.imageDesktop} media="(min-width: 768px)" />
            <StyledImg src={image.imageMobile} alt={image.alternativeText} />
          </StyledPicture>
        </StyledPictureContainer>
        <StyledLayoutItemText>
          <Text as="h2" variant="order1" spaceStackEnd="m">
            {headline}
          </Text>
          {wltpData.map(renderWltpData)}
          {additionalLegalText && (
            <Text as="p" variant="copy2">
              {additionalLegalText}
            </Text>
          )}
          <ButtonGroup variant="block-buttons" spaceStackStart="xxl">
            <Button
              variant="primary"
              href={primaryLink.href}
              onClick={() =>
                linkTracking && linkTracking(primaryLink.href, primaryLink.text, 'primary')
              }
            >
              {primaryLink.text}
            </Button>
            {secondaryLink && (
              <Button
                variant="secondary"
                href={secondaryLink.href}
                onClick={() =>
                  linkTracking && linkTracking(secondaryLink.href, secondaryLink.text, 'secondary')
                }
              >
                {secondaryLink.text}
              </Button>
            )}
          </ButtonGroup>
        </StyledLayoutItemText>
      </StyledHighlightedCarTeaser>
    </>
  );
};
