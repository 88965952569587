/**
 * Represents a csref-campaign-parameter.
 *
 * See http://csref.audibusinessinnovation.com/
 */
export class Csref {
  public static fromUrl(url: string): Csref | undefined {
    const csref = new URLSearchParams(url.split('?')[1]).get('csref');
    return csref ? new Csref(csref) : undefined;
  }

  private readonly params: string[];

  constructor(csref: string) {
    this.params = csref.split(':');
  }

  public campaignName(): string {
    return this.params[3];
  }

  public additionalInformation(): string {
    return this.params.filter((param) => param.startsWith('ai~')).map((ai) => ai.substr(3))[0];
  }
}
