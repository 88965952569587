import {
  FeatureServiceBinder,
  FeatureServiceProviderDefinition,
  FeatureServices,
  SharedFeatureService,
} from '@feature-hub/core';
import { Logger } from '@feature-hub/logger';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { EnvConfigServiceV1 } from '@volkswagen-onehub/audi-env-config-service/dist/index';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service/dist-es';

import { IPersonalizationOverrides } from './internal-types';
import { PersonalizationConfigService } from './shared/PersonalizationConfigService';
import { IPersonalizationServiceV1 } from './types';
import { PersonalizationServiceV1 } from './v1/PersonalizationService';
import { PersonalizationServiceV2 } from './v2/PersonalizationService';

export * from './types';

export interface IPersonalizationDependencies extends FeatureServices {
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi:envConfigService': EnvConfigServiceV1;
  readonly 's2:server-request': ServerRequestV1;
}

export interface ISharedPersonalizationService extends SharedFeatureService {
  readonly '1.0.0': FeatureServiceBinder<IPersonalizationServiceV1>;
}

/**
 * Returns a factory for {@link IPersonalizationServiceV1}s.
 */
export const definePersonalizationService = (
  overrides?: IPersonalizationOverrides
): FeatureServiceProviderDefinition<ISharedPersonalizationService, IPersonalizationDependencies> => {
  return {
    id: 'audi-personalization-service',
    dependencies: {
      featureServices: {
        'locale-service': '^1.0.0',
        'audi:envConfigService': '^1.0.0',
      },
    },
    optionalDependencies: {
      featureServices: {
        's2:logger': '^1.0.0',
        's2:server-request': '^1.0.0',
      },
    },
    create: (env) => {
      const localeService: LocaleServiceV1 = env.featureServices['locale-service'];
      const envConfigService: EnvConfigServiceV1 = env.featureServices['audi:envConfigService'];

      const logger: Logger = (env.featureServices['s2:logger'] as Logger) || console;
      const serverRequest: ServerRequestV1 = env.featureServices['s2:server-request'];

      const personalizationConfigService = new PersonalizationConfigService(
        localeService,
        envConfigService,
        overrides,
        logger
      );

      const personalizationServiceV1 = new PersonalizationServiceV1(
        personalizationConfigService,
        logger,
        serverRequest
      );

      const personalizationServiceV2 = new PersonalizationServiceV2(
        personalizationConfigService,
        logger,
        serverRequest
      );

      return {
        '1.0.0': () => ({
          featureService: personalizationServiceV1,
        }),
        '2.0.0': () => ({
          featureService: personalizationServiceV2,
        }),
      };
    },
  };
};
