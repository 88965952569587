import { IContentVariant } from 'src';
import { IPersonalizationInfo } from '../../types';

const DEFAULT_ITERATION_ID = 'no-iteration';

export class PersonalizationInfoHelper {
  /**
   * Helper function to put together a IPersonalizationInfo with all the defaults that usually apply.
   */
  public static compilePersonalizationInfo(
    variantName: string,
    trackParam?: string,
    iterationId?: string
  ): IPersonalizationInfo {
    return {
      variant: variantName,
      trackParam: trackParam || variantName,
      iterationId: iterationId || DEFAULT_ITERATION_ID,
    };
  }

  public static mapToContentVariant(personalizationInfo: IPersonalizationInfo) {
    return {
      contentId: personalizationInfo.variant,
      iterationId: personalizationInfo.iterationId,
      trackParam: personalizationInfo.trackParam,
    } as IContentVariant;
  }
}
