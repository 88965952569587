import React from 'react';
import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';

import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';

import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { EditModeContextProvider } from './context/useEditModeVariant';
import { ContextProvider } from './context';

import { createSSRState, deserializeSSRState, getStateCreator } from './utils/stateUtils';
import { CSRState, Dependencies, SSRState } from './FeatureAppTypes';
import AsyncFeatureApp from './FeatureApp';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    externals: {
      '@audi/audi-ui-react': '^1.6.0',
      '@feature-hub/react': '^2.7.0',
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '~5.1.1',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-personalization-service': '^2.0.0',
      'dbad:audi-vue-formatter-service': '1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^1.0.0',
      'audi-footnote-reference-service': '3.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [],
  create: ({ featureServices, featureAppId }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      'audi-content-service': contentService,
      'audi-tracking-service': trackingService,
      's2:logger': logger,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'audi-personalization-service': personalizationService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'gfa:locale-service': localeService,
    } = featureServices;
    const useCase = 'highlighted-car-teaser';

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = 'audi-highlighted-car-teaser';
    }

    const content = contentService.getContent();
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    const createState = getStateCreator(
      useCase,
      featureAppId,
      personalizationService,
      vueFormatterService,
      localeService,
      logger
    );

    let ssrState: SSRState = 'loading';
    let csrState: CSRState = 'loading';
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            ssrState = await createSSRState(
              useCase,
              featureAppId,
              content,
              personalizationService,
              vueFormatterService,
              localeService,
              logger
            );
            serializedStateManager?.register(() => JSON.stringify(ssrState));
          }
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (!serializedState || serializedState === `"skeleton"`) {
        csrState = () => createState(content);
      } else {
        csrState = deserializeSSRState(serializedState);
      }
    }
    return {
      render: () => (
        <AudiPlatformProvider>
          <InViewContextProvider logger={logger}>
            <ContentContextProvider contentService={contentService}>
              <ContextProvider featureServices={featureServices} useCase={useCase}>
                <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                  <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                    <EditModeContextProvider>
                      <AsyncFeatureApp
                        ssrState={ssrState}
                        csrState={csrState}
                        createState={createState}
                      />
                    </EditModeContextProvider>
                  </div>
                </FootnoteContextProvider>
              </ContextProvider>
            </ContentContextProvider>
          </InViewContextProvider>
        </AudiPlatformProvider>
      ),
    };
  },
};

export default featureAppDefinition;
