export const USE_CASE_LOCAL_STORAGE_KEY = 'audiPersonalizationUseCases';

export class UseCaseHelper {
  /**
   * Helper function to initialise the use case definitions.
   * This method is called from the constructor, should return immediately, and does not contain a backend request.
   */
  public static initialiseUseCases(useCaseOverrides?: { [key: string]: any }): { [key: string]: any } {
    // if there is an override structure, use that one
    if (useCaseOverrides) {
      return useCaseOverrides;
    }
    const fromLocalStorage = UseCaseHelper.getUseCasesFromLocalStorage();
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
    return {};
  }

  /**
   * Helper function to get the use case definitions from local storage.
   */
  private static getUseCasesFromLocalStorage(): { [key: string]: any } | undefined {
    try {
      const fromLocalStorage = localStorage.getItem(USE_CASE_LOCAL_STORAGE_KEY);
      if (fromLocalStorage) {
        const parsed = JSON.parse(fromLocalStorage);
        if (parsed && typeof parsed === 'object') {
          return parsed;
        }
      }
    } catch (e) {
      /*
       * Catch all and ignore.
       * If local storage is not supported or if the content is not formatted properly we just ignore it.
       */
    }
    return undefined;
  }
}
