import { createElement, ReactNode } from 'react';
import { Logger } from '@feature-hub/core';
import { VueFormatterServiceInterfaceV1 } from '@volkswagen-onehub/audi-vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import {
  getConsumptionsAndEmissions,
  FootnoteReference,
} from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';

import {
  IPersonalizationServiceV2,
  PersonalizationDataSource,
} from '@volkswagen-onehub/audi-personalization-service';
import { Content } from '../EditorContentTypes';
import { mapToTeaserProps } from './mapToTeaserProps';
import { getPersonalizedContent } from './getPersonalizedContent';
import { ContentState } from '../FeatureAppTypes';

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
}

const deserializeReactNodeArray = (
  serializedProperty?: string | (string | Record<string, unknown>)[]
): undefined | string | ReactNode[] => {
  if (!serializedProperty || typeof serializedProperty === 'string') {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return serializedProperty;
  }
  return serializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(FootnoteReference, serializedReactNode.props as undefined);
  });
};

const deserializeReactNodeArrayInWltpData = (wltpData: SerializedWltpProps[]) => {
  return wltpData.map(({ formattedConsumption, formattedEmission }) => {
    return {
      formattedConsumption: deserializeReactNodeArray(formattedConsumption),
      formattedEmission: deserializeReactNodeArray(formattedEmission),
    };
  });
};

export function deserializeSSRState(serializedState: string): ContentState {
  const props = JSON.parse(serializedState);
  return {
    ...props,
    teaserProps: {
      ...props.teaserProps,
      headline: deserializeReactNodeArray(props.teaserProps.headline),
      additionalLegalText: deserializeReactNodeArray(props.teaserProps.additionalLegalText),
      wltpData: deserializeReactNodeArrayInWltpData(props.teaserProps.wltpData),
    },
  } as ContentState;
}

export async function createSSRState(
  useCase: string,
  featureAppId: string,
  content: Content,
  personalizationService: IPersonalizationServiceV2,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger
): Promise<ContentState | 'skeleton'> {
  const personalizationType = content.personalizationOption.type;

  if (personalizationType === 'none' || content.personalizedVariants.length === 0) {
    const selectedVariant = getPersonalizedContent(content);
    const consumptionsAndEmissions = await getConsumptionsAndEmissions(
      content.default.legalData.wltpKeys,
      vueFormatterService,
      localeService,
      logger
    );

    return {
      selectedVariant,
      teaserProps: mapToTeaserProps(
        content.default,
        consumptionsAndEmissions,
        content.spacingOption
      ),
      personalizationType,
    };
  }
  if (personalizationType === 'campaign') {
    const personalizationInfo = await personalizationService.getContentVariant(
      useCase,
      featureAppId,
      PersonalizationDataSource.Campaign
    );

    const selectedVariant = getPersonalizedContent(content, personalizationInfo.contentId);
    const consumptionsAndEmissions = await getConsumptionsAndEmissions(
      selectedVariant.legalData.wltpKeys,
      vueFormatterService,
      localeService,
      logger
    );
    return {
      selectedVariant,
      personalizationInfo,
      teaserProps: mapToTeaserProps(
        selectedVariant,
        consumptionsAndEmissions,
        content.spacingOption
      ),
      personalizationType,
    };
  }
  return 'skeleton';
}

async function createCSRState(
  useCase: string,
  featureAppId: string,
  content: Content,
  personalizationService: IPersonalizationServiceV2,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger
): Promise<ContentState> {
  const personalizationType = content.personalizationOption.type;
  if (personalizationType === 'none' || content.personalizedVariants.length === 0) {
    const selectedVariant = getPersonalizedContent(content);
    const consumptionsAndEmissions = await getConsumptionsAndEmissions(
      content.default.legalData.wltpKeys,
      vueFormatterService,
      localeService,
      logger
    );
    return {
      selectedVariant,
      teaserProps: mapToTeaserProps(
        content.default,
        consumptionsAndEmissions,
        content.spacingOption
      ),
      personalizationType,
    };
  }

  const personalizationInfo = await personalizationService.getContentVariant(
    useCase,
    featureAppId,
    personalizationType === 'behavioral'
      ? PersonalizationDataSource.Behavioural
      : PersonalizationDataSource.Campaign
  );

  const selectedVariant = getPersonalizedContent(content, personalizationInfo.contentId);
  const consumptionsAndEmissions = await getConsumptionsAndEmissions(
    selectedVariant.legalData.wltpKeys,
    vueFormatterService,
    localeService,
    logger
  );
  return {
    selectedVariant,
    personalizationInfo,
    teaserProps: mapToTeaserProps(selectedVariant, consumptionsAndEmissions, content.spacingOption),
    personalizationType,
  };
}

export function getStateCreator(
  useCase: string,
  featureAppId: string,
  personalizationService: IPersonalizationServiceV2,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger
  // eslint-disable-next-line no-unused-vars
): (content: Content) => Promise<ContentState> {
  return (content: Content) =>
    createCSRState(
      useCase,
      featureAppId,
      content,
      personalizationService,
      vueFormatterService,
      localeService,
      logger
    );
}

export async function getEditVariant(
  content: Content,
  contentId: string,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger
): Promise<ContentState> {
  const personalizationType = content.personalizationOption.type;
  let selectedVariant = { ...content.default, isPersonalized: false };

  if (contentId !== 'default') {
    const variant = content.personalizedVariants.find((item) => item.contentId === contentId);
    if (variant !== undefined) {
      selectedVariant = { ...variant, isPersonalized: false };
    }
  }

  const consumptionsAndEmissions = await getConsumptionsAndEmissions(
    selectedVariant.legalData.wltpKeys,
    vueFormatterService,
    localeService,
    logger
  );
  return {
    selectedVariant,
    teaserProps: mapToTeaserProps(selectedVariant, consumptionsAndEmissions, content.spacingOption),
    personalizationType,
  };
}
