import React, { RefObject, useRef, useContext, ReactElement } from 'react';
import { Logger } from '@feature-hub/core';

import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';

import { VueFormatterServiceInterfaceV1 } from '@volkswagen-onehub/audi-vue-formatter-service';
import { RenderMode } from '@volkswagen-onehub/audi-render-mode-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Dependencies } from '../FeatureAppTypes';

interface State {
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly logger?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly footnoteScope?: AudiFootnoteReferenceServiceInterfaceV3;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: GfaLocaleServiceV1;
  readonly useCase: string;
  readonly renderMode: RenderMode;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly useCase: string;
  readonly children: ReactElement<any, any>;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({
  featureServices: {
    's2:logger': logger,
    'audi-tracking-service': trackingService,
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'audi-render-mode-service': renderModeService,
    'gfa:locale-service': localeService,
  },
  useCase,
  children,
}: ContextValue): ReactElement<any, any> => {
  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = {
    inViewRef,
    logger,
    trackingService,
    vueFormatterService,
    localeService,
    renderMode: renderModeService?.getRenderMode() || RenderMode.DEFAULT,
    useCase,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => {
  return useContext(Context).logger;
};

export const useRenderMode = (): RenderMode | undefined => {
  return useContext(Context).renderMode;
};

export const useUseCase = (): string => {
  return useContext(Context).useCase;
};
